import React from "react";
import { graphql, PageProps } from "gatsby";
import { Profile } from "../../../components";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import { Layout } from "../../../layouts";
import { getImage } from "@bond-london/gatsby-graphcms-components";
import { Query } from "../../../generated/graphql-types";

const component: React.FC<PageProps<Pick<Query, "graphCmsPerson">>> = ({
  path,
  data: { graphCmsPerson: person },
}) => {
  if (!person) return null;
  return (
    <Layout title={person.name} keywords={person.keywords} pagePath={path}>
      <Profile
        sector={person.coreSectors[0]}
        bioRTF={getCleanedRTF(person.bio)}
        coreSectors={person.coreSectors}
        country={person.country}
        education={person.education}
        expertise={person.expertise}
        image={getImage(person.image)}
        jobTitle={person.jobTitle}
        linkedin={person.linkedIn}
        name={person.name}
        quote={person.quote}
      />
    </Layout>
  );
};

export default component;

export const fullPersonQuery = graphql`
  query FullPersonQuery($id: String) {
    graphCmsPerson(id: { eq: $id }) {
      menu {
        ...MenuFragment
      }
      bio {
        cleaned
      }
      coreSectors
      country
      education
      expertise
      image {
        ...ImageFragment
      }
      jobTitle
      company
      linkedIn
      name
      quote
      keywords
    }
  }
`;
