import classNames from "classnames";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { RTF } from "../elements";
import { SingleProfile } from "./SingleProfile";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";

interface Props {
  bioRTF?: CleanedRTF;
  coreSectors?: readonly string[];
  sector?: string;
  country?: string;
  education?: readonly string[];
  expertise?: readonly string[];
  image?: IGatsbyImageData;
  jobTitle: string;
  linkedin?: string;
  name: string;
  quote?: string;
}

export const Profile: React.FC<Props> = ({
  bioRTF,
  coreSectors,
  sector,
  country,
  education,
  expertise,
  image,
  jobTitle,
  linkedin,
  name,
  quote,
}) => {
  const hasExtra = !!(
    coreSectors?.length &&
    education?.length &&
    expertise?.length
  );
  return (
    <>
      <section className="py-section relative page-grid z-10">
        <div
          className={classNames(
            "md:row-start-1 md:row-span-1",
            "col-start-3 col-span-10",
            "md:col-start-2 md:col-span-4",
            "xl:col-start-3 xl:col-span-3"
          )}
        >
          <SingleProfile
            sector={sector}
            country={country}
            image={image}
            jobTitle={jobTitle}
            linkedin={linkedin}
            name={name}
            quote={quote}
            showSector={false}
            showTitle={true}
            showQuote={true}
            showCountry={true}
          />
        </div>
        {bioRTF && (
          <>
            <div
              className={classNames(
                "md:row-start-1 md:row-span-7",
                "text-right-margin",
                "col-start-2 col-span-12",
                "md:col-start-7 md:col-span-7"
              )}
            >
              <h3 className="h3 mt-60px md:mt-0">Summary Bio</h3>
              <RTF
                content={bioRTF}
                className="mt-20px space-y-20px md:space-y-40px"
              />
            </div>
          </>
        )}
      </section>
      {hasExtra && (
        <section className="pb-100px pt-0 relative page-grid z-10">
          {!!expertise?.length && (
            <>
              <h3
                className={classNames(
                  "h3 xl:mt-75px",
                  "md:row-start-1 md:row-span-1",
                  "col-start-2 col-span-12",
                  "md:col-start-2 md:col-span-4"
                )}
              >
                Expertise
              </h3>
              <ul
                className={classNames(
                  "mt-30px",
                  "text-right-margin",
                  "md:row-start-2 md:row-span-1",
                  "col-start-2 col-span-12",
                  "md:col-start-2 md:col-span-4 pl-20px"
                )}
              >
                {expertise.map((e, i) => (
                  <li className="list-disc" key={i}>
                    {e}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!coreSectors?.length && (
            <>
              <h3
                className={classNames(
                  "h3 mt-60px md:mt-0 xl:mt-75px",
                  "md:row-start-1 md:row-span-1",
                  "col-start-2 col-span-12",
                  " md:col-span-4"
                )}
              >
                Core Sectors
              </h3>
              <ul
                className={classNames(
                  " mt-30px",
                  "text-right-margin",
                  "md:row-start-2 md:row-span-1",
                  "col-start-2 col-span-12",
                  " md:col-span-4 pl-20px"
                )}
              >
                {coreSectors.map((s, i) => (
                  <li className="list-disc" key={i}>
                    {s}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!education?.length && (
            <>
              <h3
                className={classNames(
                  "h3 xl:mt-75px",
                  // "md:row-start-3 md:row-span-1",
                  "col-start-2 col-span-12",
                  "md:col-start-10"
                )}
              >
                Education
              </h3>
              <ul
                className={classNames(
                  "mt-30px",
                  "text-right-margin",
                  // "md:row-start-4 md:row-span-1",
                  "col-start-2 col-span-12",
                  "md:col-start-10 pl-20px "
                )}
              >
                {education.map((e, i) => (
                  <li key={i} className="list-disc">
                    {e}
                  </li>
                ))}
              </ul>
            </>
          )}
        </section>
      )}
    </>
  );
};
